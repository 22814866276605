import React from 'react';

function Footer() {
  return (
    <footer className="bg-primary">
      <p>
        All rights reserved to Ahmed Al-Farouq - 2022
      </p>
    </footer>
  );
}

export default Footer;
